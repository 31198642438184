import { SearchPeriodType } from '@hotelplan/graphql.types';
import {
  trackTravelDates,
  TravelApplySourceType,
  TravelDatesEventType,
} from '@hotelplan/libs.tracking';
import { track } from '@hotelplan/libs.tracking-events';

export const trackCalendar = type => () =>
  track({ event: type, eventMetadata: {}, _clear: true });

export const trackApplyEvents = type => (
  state,
  source?: TravelApplySourceType
) =>
  trackTravelDates({
    type,
    payload: {
      duration: state.duration.duration.join('-'),
      exact: state.searchType === SearchPeriodType.Exact,
      from: state.departureDate,
      to: state.returnDate,
      source,
    },
  });

// *** trackCalendar ***

export const trackModalOpen = trackCalendar(
  TravelDatesEventType.TRAVEL_DATES_OPEN
);

export const trackModalClose = trackCalendar(
  TravelDatesEventType.TRAVEL_DATES_CLOSE
);

export const trackCalendarOpen = trackCalendar(
  TravelDatesEventType.TRAVEL_DATES_OPEN_CALENDAR
);

export const trackCalendarClose = trackCalendar(
  TravelDatesEventType.TRAVEL_DATES_CLOSE_CALENDAR
);

// *** trackApplyEvents ***

export const trackModalApply = trackApplyEvents(
  TravelDatesEventType.TRAVEL_DATES_APPLY
);

export const trackModalUpdate = trackApplyEvents(
  TravelDatesEventType.TRAVEL_DATES_UPDATE
);

export const trackCalendarUpdate = trackApplyEvents(
  TravelDatesEventType.TRAVEL_DATES_UPDATE_CALENDAR
);

export const trackCalendarApply = trackApplyEvents(
  TravelDatesEventType.TRAVEL_DATES_APPLY_CALENDAR
);
