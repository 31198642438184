import { useDropdownLogic } from 'components/domain/search-travel-dates/dropdowns/useDropdownLogic';
import {
  trackCalendarClose,
  trackCalendarOpen,
} from 'components/domain/search-travel-dates/tracking';

export enum EDatesInputType {
  RETURN_DATE = 'RETURN_DATE',
  DEPARTURE_DATE = 'DEPARTURE_DATE',
  ANY = 'ANY',
}

export type TDatesDropdownHandler = {
  getRef(type: EDatesInputType): React.MutableRefObject<HTMLInputElement>;
  isOpened(type: EDatesInputType): boolean;
  openCalendar(type: EDatesInputType): () => void;
  closeCalendar: () => void;
};

export const useTravelDatesDropdownHandler = (): TDatesDropdownHandler => {
  const [refDep, isOpenedDep, openDep, closeDep] = useDropdownLogic();
  const [refRet, isOpenedRet, openRet, closeRet] = useDropdownLogic();

  const getRef = (type: EDatesInputType) => {
    if (type === EDatesInputType.DEPARTURE_DATE) {
      return refDep;
    }

    if (type === EDatesInputType.RETURN_DATE) {
      return refRet;
    }

    return null;
  };

  const isOpened = (type: EDatesInputType) => {
    if (type === EDatesInputType.DEPARTURE_DATE) {
      return isOpenedDep;
    }

    if (type === EDatesInputType.RETURN_DATE) {
      return isOpenedRet;
    }

    if (type === EDatesInputType.ANY) {
      return isOpenedRet || isOpenedDep;
    }

    return false;
  };

  const openCalendar = (type: EDatesInputType) => () => {
    if (!isOpenedRet && !isOpenedDep) trackCalendarOpen();

    if (type === EDatesInputType.DEPARTURE_DATE) {
      closeRet();
      openDep();
    }

    if (type === EDatesInputType.RETURN_DATE) {
      closeDep();
      openRet();
    }
  };

  const closeCalendar = () => {
    if (isOpenedRet || isOpenedDep) setTimeout(trackCalendarClose);

    closeRet();
    closeDep();
  };

  return { getRef, isOpened, openCalendar, closeCalendar };
};
