import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

interface ILabelProps {
  htmlFor?: string;
  label?: string;
  hideLabel?: boolean;
  className?: string;
}

const BaseLabel = styled.label<{ hide?: boolean }>(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn<{ hide?: boolean }>(
    {
      display: 'flex',
      margin: 0,
    },
    ({ hide }) =>
      hide
        ? {
            fontSize: '0px',
          }
        : {
            alignItems: 'center',
            ...FONT_SIZE.SMALL,
            pl: ['5px'],
            color: 'black',
            mb: [1, 2],
            span: {
              ml: 1,
            },
          }
  )
);

const CustomLabel: React.FC<React.PropsWithChildren<ILabelProps>> = ({
  label,
  hideLabel,
  htmlFor,
  children,
  className,
}) => {
  return (
    <BaseLabel
      htmlFor={htmlFor}
      className={`label ${className}`}
      hide={hideLabel}
    >
      {label}
      {children}
    </BaseLabel>
  );
};

export default CustomLabel;
