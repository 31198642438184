import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import {
  ITravelDatesState,
  TravelDatesCustomConfiguration,
  TravelDatesField,
} from '@hotelplan/components.common.travel-dates';
import { ENTER_KEY } from 'components/domain/keyboard-navigation/keyboard-navigation.constant';
import DropdownVariants from 'components/domain/search-travel-dates/dropdowns/dropdown-variants';
import TravelDatesContextProvider, {
  useCompactSelectContext,
} from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';
import { getTranslatedShortInfo } from 'components/domain/search-travel-dates/travel-dates-container.utils';
import { CUSTOM_FLEXIBLE_DURATION_VALUES } from './search-travel-dates.constants';

interface ITravelDatesFieldContainerProps {
  disabled?: boolean;
  noVariants?: boolean;
  customConfiguration?: TravelDatesCustomConfiguration;
  parentItemIndex?: number;
  parentItemName?: string;
  onChange?: (travelDates: ITravelDatesState | null) => void;
  side?: string;
  customLabel?: React.ReactNode;
}

interface ITravelDatesFieldContainerChildProps {
  disabled?: boolean;
  noVariants?: boolean;
  customLabel?: React.ReactNode;
}

function SearchTravelDatesChild({
  disabled,
  customLabel,
  noVariants,
}: ITravelDatesFieldContainerChildProps): React.ReactElement {
  const {
    initialState,
    customConfiguration,
    modalOrDropdownHandler: { isControlOpened, openControl },
  } = useCompactSelectContext();
  const { t } = useTranslation('search');

  const textInputRef = useRef<HTMLInputElement>(null);

  const translatedShortInformation = getTranslatedShortInfo(
    t,
    initialState,
    customConfiguration
  );

  return (
    <>
      <TravelDatesField
        disabled={disabled}
        textInputRef={textInputRef}
        isDropdownOpened={isControlOpened}
        translatedShortInformation={translatedShortInformation}
        customLabel={customLabel}
        onClick={openControl}
        onKeyPress={e => {
          if (e.key === ENTER_KEY) {
            e.preventDefault();
            openControl();
          }
        }}
      >
        <DropdownVariants textInputRef={textInputRef} noVariants={noVariants} />
      </TravelDatesField>
    </>
  );
}

const SearchTravelDates = ({
  disabled,
  side = 'center',
  parentItemIndex,
  parentItemName,
  customConfiguration,
  onChange,
  customLabel,
  noVariants,
}: ITravelDatesFieldContainerProps) => {
  return (
    <TravelDatesContextProvider
      parentItemIndex={parentItemIndex}
      parentItemName={parentItemName}
      customConfiguration={customConfiguration}
      onChange={onChange}
      side={side}
      customFlexibleDurationValues={CUSTOM_FLEXIBLE_DURATION_VALUES}
    >
      <SearchTravelDatesChild
        disabled={disabled}
        customLabel={customLabel}
        noVariants={noVariants}
      />
    </TravelDatesContextProvider>
  );
};

export default SearchTravelDates;
