import addDays from 'date-fns/addDays';
import addYears from 'date-fns/addYears';
import subYears from 'date-fns/subYears';
import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import {
  TravelDatesCustomConfiguration,
  TravelDatesDesktopDropdown as DropdownCalendar,
} from '@hotelplan/components.common.travel-dates';
import { useCompactSelectContext } from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';
import { useCloseDropdownLogic } from 'components/domain/search-travel-dates/dropdowns/useCloseDropdownLogic';
import { trackCalendarApply } from 'components/domain/search-travel-dates/tracking';

const Calendar = styled(DropdownCalendar)(({ theme: { colors } }) => ({
  '&.dropdown-mobile-calendar': {
    '.travel-dates-information': {
      display: 'none',
    },
    '.footer': {
      padding: '16px',
      borderRadius: '5px',
      textAlign: 'center',
      '.applyButton': {
        width: '100%',
      },
    },
    '.body': {
      marginTop: '8px !important',
    },
    '.date-picker .DayPicker-Month .DayPicker-Caption': {
      marginBottom: '6px',
    },
    '.date-picker .DayPicker-Month .DayPicker-Weekdays': {
      borderTop: `1px solid ${colors.lightGreyFirst}`,
      borderBottom: `1px solid ${colors.lightGreyFirst}`,

      '&:after': {
        display: 'none',
      },
    },
  },
  '.date-picker .DayPicker-Month': {
    width: '100%',
  },
  '.date-picker .DayPicker-Weekdays  .DayPicker-Weekday': {
    fontSize: '16px',
  },
  '.date-picker .DayPicker-NavBar svg': {
    width: '24px',
    height: '24px',
  },
  '.date-picker .DayPicker-NavBar button': {
    padding: '0',
  },
}));

const today = new Date();

const configuration = {
  hasApplyBtn: false,
  hasNavbar: true,
  hasCustomWeekDayElement: false,
  numberOfMonthsToShow: 1,
  canChangeMonth: true,
  changeYears: {
    enabled: true,
    minYear: subYears(today, 1).getFullYear(), // 1 year before
    maxYear: addYears(today, 1).getFullYear(), // 1 year after
  },
  disableFooter: true,
};

const DEFAULT_DURATION_DAYS = 14;

type TProps = React.PropsWithChildren<{
  customConfiguration?: TravelDatesCustomConfiguration;
  initialReturnDate: Date;
  initialMonth: Date;
  holderRef: React.MutableRefObject<HTMLDivElement>;
  desktop: boolean;
}>;

const CompactCalendar: React.FC<TProps> = ({
  customConfiguration,
  initialReturnDate,
  initialMonth,
  holderRef,
  desktop,
  children,
}: TProps) => {
  const [t] = useTranslation('forms');
  const {
    state,
    localState,
    travelDatesHandlers,
    onChange,
    datesHandler: { closeCalendar },
    updateSate,
  } = useCompactSelectContext();

  const datePickerDropdownRef = useRef<HTMLDivElement>(null);
  const datePickerContainerRef = useRef<HTMLDivElement>(null);

  useCloseDropdownLogic(
    [datePickerContainerRef, datePickerDropdownRef, holderRef],
    closeCalendar
  );

  const handler = {
    ...travelDatesHandlers,
    setDepartureDate(day: Date) {
      const newReturn = addDays(day, DEFAULT_DURATION_DAYS);

      travelDatesHandlers.setDepartureDate(day);
      travelDatesHandlers.setReturnDate(newReturn);
      setTimeout(() => {
        trackCalendarApply({
          ...state,
          departureDate: day,
          returnDate: newReturn,
        });

        closeCalendar();
      });
    },
    setReturnDate(day: Date) {
      travelDatesHandlers.setReturnDate(day);
      setTimeout(() => {
        trackCalendarApply({ ...state, returnDate: day });
        closeCalendar();
      });
    },
  };

  const month = desktop
    ? initialMonth
    : customConfiguration.onlyReturnDate
    ? localState.returnDate
    : localState.departureDate;

  return (
    <Calendar
      key={month.toDateString()}
      applyText={t('forms:apply.button')}
      travelDatesState={state}
      className={desktop ? 'dropdown-calendar' : 'dropdown-mobile-calendar'}
      containerRef={datePickerContainerRef}
      dropdownRef={datePickerDropdownRef}
      isShown={true}
      side={`right`}
      initialReturnDate={initialReturnDate}
      travelDatesHandlers={handler}
      customConfiguration={{ ...customConfiguration, closeOnSelection: true }}
      onChange={onChange}
      configuration={{
        ...configuration,
        initialMonth: month,
        disableFooter: desktop,
        hasApplyBtn: false,
        closeOnSaveButtonClick: true,
      }}
      closeDropdown={closeCalendar}
      onSaveTravelDates={dates => {
        updateSate(dates);
      }}
      icons={{
        minusYear: `double-arrow-button-left`,
        plusYear: `double-arrow-button-right`,
        minusMonth: `arrow-button-left`,
        plusMonth: `arrow-button-right`,
      }}
    >
      {children}
    </Calendar>
  );
};

export default CompactCalendar;
