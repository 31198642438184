import { useField } from '@hotelplan/components.common.forms';
import { useTravelRooms } from '@hotelplan/components.common.travel-rooms';
import { TravelType } from '@hotelplan/graphql.types';
import { useMaxFourSearchingRoomsFeatureEnabled } from 'components/domain/travelRooms/useMaxFourSearchingRoomsFeatureEnabled';

export const useFourTravelRooms = () => {
  const [travelType] = useField('travelType');
  const isMaxSearchingRoomsEnabled = useMaxFourSearchingRoomsFeatureEnabled();

  const isSearchForFourRoomsEnabled =
    isMaxSearchingRoomsEnabled && travelType !== TravelType.Flight;

  return useTravelRooms(isSearchForFourRoomsEnabled);
};
