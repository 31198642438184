import {
  EXACT_DURATION_VALUES,
  IDurationValue,
} from '@hotelplan/components.common.travel-dates';

export const CUSTOM_FLEXIBLE_DURATION_VALUES: IDurationValue[] = [
  ...EXACT_DURATION_VALUES,
  { duration: [1, 8], type: 'night' },
  { duration: [1, 3], type: 'night' },
  { duration: [3, 7], type: 'night' },
  { duration: [4, 5], type: 'night' },
  { duration: [6, 8], type: 'night' },
  { duration: [7, 10], type: 'night' },
  { duration: [9, 15], type: 'night' },
  { duration: [16, 22], type: 'night' },
];

export const SELECT_DAY_EVENT = `select_day`;
