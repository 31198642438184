import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { addDays, getTodaysDate } from '@hotelplan/libs.utils';
import { SearchPeriodType } from '@hotelplan/platform-graphql-api';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import CompactCalendar from 'components/domain/search-travel-dates/dropdowns/compact-select/compact-calendar';
import { useCompactSelectContext } from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';
import { EDatesInputType } from 'components/domain/search-travel-dates/dropdowns/useTravelDatesDropdownHandler';

const Title = styled.div(({ theme: { colors, FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.S,
    paddingBottom: '8px',
    margin: '8px 18px -10px',
    borderBottom: `1px solid ${colors.lightGreyFirst}`,
  })
);

const ModalCloseButton = styled(BsButtonWithIcon)(
  sx2CssThemeFn({
    ml: 'auto',
    backgroundColor: 'inherit',
    '.icon': {
      variant: ['icons.md', 'icons.lg'],
    },
  })
);

const Header = styled.div(({ theme: { colors } }) => ({
  display: 'flex',
  borderBottom: `1px solid ${colors.lightGreyFirst}`,
  padding: '8px 16px 8px',
  '.title': {
    borderBottom: '0',
    paddingBottom: '0',
    margin: 0,
  },
}));

type TProps = {
  desktop: boolean;
  isReturn: boolean;
  wrapperRef: React.MutableRefObject<HTMLDivElement>;
  minReturnDate: Date;
};

const Single: React.FC<TProps> = ({
  desktop,
  isReturn,
  wrapperRef,
  minReturnDate,
}: TProps) => {
  const [t] = useTranslation('common');
  const {
    state,
    initialState,
    resetLocalState,
    datesHandler: { isOpened, closeCalendar },
    customConfiguration,
  } = useCompactSelectContext();

  const returnDate = state.returnDate || initialState.returnDate;
  const departureDate = state.departureDate || initialState.departureDate;
  const isExactSearchType = state.searchType === SearchPeriodType.Exact;
  const translationPostfix = isExactSearchType ? '.exact' : '';

  const month = isReturn ? returnDate : departureDate;

  const title = t(
    isReturn
      ? `single.return.calendar.title${translationPostfix}`
      : `single.departure.calendar.title${translationPostfix}`
  );

  return (
    <CompactCalendar
      desktop={desktop}
      key={String(month)}
      holderRef={wrapperRef}
      customConfiguration={{
        ...customConfiguration,
        onlyDepartureDate: isOpened(EDatesInputType.DEPARTURE_DATE),
        onlyReturnDate: isReturn,
        maxDate: addDays(getTodaysDate(), 365),
        minDate: isReturn ? minReturnDate : undefined,
      }}
      initialReturnDate={initialState.returnDate}
      initialMonth={month}
    >
      <Header>
        <Title className="title">{title}</Title>
        {!desktop && (
          <ModalCloseButton
            variant={E_BUTTON_TYPE.ICON_BUTTON}
            className="close-button"
            data-id="close"
            icon={{ name: 'close' }}
            onClick={() => {
              resetLocalState(isReturn);
              closeCalendar();
            }}
          >
            {t('common:close')}
          </ModalCloseButton>
        )}
      </Header>
    </CompactCalendar>
  );
};

export default Single;
