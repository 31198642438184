import { useOnClickOutside } from '@hotelplan/libs.hooks-dom';
import { ESCAPE_KEY } from 'components/domain/keyboard-navigation/keyboard-navigation.constant';
import { useListenKeyboardClick } from 'components/domain/keyboard-navigation/useListenKeyboardClick';

export const useCloseDropdownLogic = (refs, handler) => {
  useOnClickOutside<HTMLDivElement | HTMLInputElement>(refs, handler, true);

  const escapeClickListener = (e: KeyboardEvent) => {
    if (e.key === ESCAPE_KEY) {
      handler();
    }
  };

  useListenKeyboardClick(true, escapeClickListener);
};
