import { getShortInformation } from '@hotelplan/components.common.travel-dates';

export const getTranslatedShortInfo = (t, travelDatesData, config) =>
  travelDatesData
    ? getShortInformation(t, {
        departureDate: travelDatesData.departureDate,
        returnDate: travelDatesData.returnDate,
        durationData: travelDatesData.duration,
        onlyDepartureDate: config?.onlyDepartureDate,
        onlyReturnDate: config?.onlyReturnDate,
        showDuration: !config?.onlyDatesInfo,
      }).travelDates
    : '';
