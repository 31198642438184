import { useTranslation } from 'next-i18next';
import React from 'react';
import { SearchPeriodType } from '@hotelplan/platform-graphql-api';
import DateInput from 'components/domain/search-travel-dates/dropdowns/compact-select/date-input';
import DatesController from 'components/domain/search-travel-dates/dropdowns/dates-controller';
import { useCompactSelectContext } from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';
import { EDatesInputType } from 'components/domain/search-travel-dates/dropdowns/useTravelDatesDropdownHandler';

type TProps = React.PropsWithChildren<{
  range?: boolean;
}>;

const CompactDates: React.FC<TProps> = ({ children, range }: TProps) => {
  const {
    state,
    initialState,
    datesHandler: { isOpened, openCalendar },
  } = useCompactSelectContext();
  const [t] = useTranslation('common');

  const returnDate = state.returnDate || initialState.returnDate;
  const departureDate = state.departureDate || initialState.departureDate;
  const isExactSearchType = state.searchType === SearchPeriodType.Exact;
  const translationPostfix = isExactSearchType ? '.exact' : '';

  return (
    <DatesController range={range}>
      {range ? (
        <DateInput
          label={t('search_form_label.travel_dates.range')}
          date={[departureDate, returnDate]}
          isOpened={isOpened(EDatesInputType.DEPARTURE_DATE)}
          openCalendar={openCalendar(EDatesInputType.DEPARTURE_DATE)}
        />
      ) : (
        <>
          <DateInput
            label={t(
              `search_form_label.travel_dates.departure${
                range ? '.short' : translationPostfix
              }`
            )}
            date={departureDate}
            isOpened={isOpened(EDatesInputType.DEPARTURE_DATE)}
            openCalendar={openCalendar(EDatesInputType.DEPARTURE_DATE)}
          />
          <DateInput
            label={t(
              `search_form_label.travel_dates.return${
                range ? '.short' : translationPostfix
              }`
            )}
            date={returnDate}
            isOpened={isOpened(EDatesInputType.RETURN_DATE)}
            openCalendar={openCalendar(EDatesInputType.RETURN_DATE)}
          />
        </>
      )}
      {children}
    </DatesController>
  );
};

export default CompactDates;
