import { useTranslation } from 'next-i18next';
import React from 'react';
import {
  formatDuration,
  TravelDatesInformation,
} from '@hotelplan/components.common.travel-dates';
import DatesController from 'components/domain/search-travel-dates/dropdowns/dates-controller';
import { useCompactSelectContext } from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';

type TProps = {};

const AllInMobileFooter: React.FC<TProps> = ({}: TProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation('search');
  const { state, customConfiguration } = useCompactSelectContext();

  return (
    <>
      <TravelDatesInformation
        returnDate={state.returnDate}
        duration={formatDuration(t, state.duration)}
        departureDate={state.departureDate}
        onlyDates={customConfiguration?.onlyDatesInfo}
        onlyDepartureDate={customConfiguration?.onlyDepartureDate}
        onlyReturnDate={customConfiguration?.onlyReturnDate}
        language={language}
      />
      <DatesController />
    </>
  );
};

export default AllInMobileFooter;
