import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import CompactDates from 'components/domain/search-travel-dates/dropdowns/compact-select/compact-dates';
import CompactFooter from 'components/domain/search-travel-dates/dropdowns/compact-select/compact-footer';
import { useCompactSelectContext } from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';
import { useCloseDropdownLogic } from 'components/domain/search-travel-dates/dropdowns/useCloseDropdownLogic';
import CalendarsVariants from './calendars-variants';
import { Wrapper } from './compact-select.styles';

type TProps = {
  range?: boolean;
};

const CompactDesktop: React.FC<TProps> = ({ range = false }: TProps) => {
  const {
    modalOrDropdownHandler: { closeControl, isControlOpened },
    datesHandler: { closeCalendar },
    resetState,
    state,
  } = useCompactSelectContext();
  const [t] = useTranslation(['common', 'forms']);
  const wrapperRef = useRef<HTMLDivElement>();

  useCloseDropdownLogic([wrapperRef], () => {
    resetState();

    if ((!state.returnDate || !state.departureDate) && range) return;

    closeCalendar();
    setTimeout(closeControl);
  });

  if (!isControlOpened) return null;

  return (
    <Wrapper ref={wrapperRef} className="desktop" range={range}>
      <CompactDates range={range}>
        <CalendarsVariants
          range={range}
          desktop={true}
          wrapperRef={wrapperRef}
        />
      </CompactDates>
      <CompactFooter applyText={t('forms:apply.button')} />
    </Wrapper>
  );
};

export default CompactDesktop;
