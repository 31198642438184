import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

export const searchFormWrapCss = sx2CssThemeFn({
  width: '100%',
  maxWidth: '1170px',
  mx: [null, 'auto'],
  borderRadius: [null, 'default'],
  mb: [2, 0],
  position: 'relative',
  backgroundColor: 'background',
  zIndex: [null, '3'],
  boxShadow: ['homeSearchFormWrap', 'none'],
  overflowX: ['auto', `visible`],
});

interface ISearchControlFormLayoutProps extends React.PropsWithChildren<{}> {
  travelTypes?: React.ReactNode;
  className?: string;
}

const SearchFormWrap = styled.div(searchFormWrapCss);

const TravelTypes = styled.div(({ theme: { media } }) => ({
  [media.tablet]: {
    overflow: 'hidden',
  },
}));

const SearchControlFormLayout: React.FC<ISearchControlFormLayoutProps> = ({
  travelTypes,
  children,
  className,
}) => {
  return (
    <SearchFormWrap
      className={`searchControlFormLayoutWrapper ${className}`}
      id="search-form-wrap"
    >
      {travelTypes && (
        <TravelTypes className="travelTypes">{travelTypes}</TravelTypes>
      )}
      {children}
    </SearchFormWrap>
  );
};

export default SearchControlFormLayout;
