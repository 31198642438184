import { useEffect } from 'react';
import {
  ITravelDatesState,
  TravelDatesCustomConfiguration,
  TravelDatesHandlers,
} from '@hotelplan/components.common.travel-dates';
import { FlightType } from '@hotelplan/platform-graphql-api';
import { SELECT_DAY_EVENT } from 'components/domain/search-travel-dates/search-travel-dates.constants';

export const selectDate = input => {
  if (typeof window === 'undefined') return;
  window.dispatchEvent(new CustomEvent(SELECT_DAY_EVENT, { detail: input }));
};

export const useFlightTravelDatesHelper = ({
  customConfiguration,
  state,
  travelDatesHandlers,
}: {
  customConfiguration: TravelDatesCustomConfiguration;
  state: ITravelDatesState;
  travelDatesHandlers: TravelDatesHandlers;
}) => {
  useEffect(() => {
    if (
      customConfiguration?.flightActiveType === FlightType.Return ||
      !customConfiguration?.flightActiveType
    )
      return;

    travelDatesHandlers.setReturnDate(state.departureDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customConfiguration?.flightActiveType]);
};
