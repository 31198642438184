import addDays from 'date-fns/addDays';
import React, { useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  areTravelDatesEqual,
  ITravelDatesState,
  TravelDatesConfiguration,
  TravelDatesCustomConfiguration,
  TravelDatesDesktopDropdown as DropdownCalendar,
  useTravelDatesDropdownPosition,
} from '@hotelplan/components.common.travel-dates';
import { TravelApplySourceType } from '@hotelplan/libs.tracking';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useCompactSelectContext } from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';
import { useCloseDropdownLogic } from 'components/domain/search-travel-dates/dropdowns/useCloseDropdownLogic';
import {
  useFlightTravelDatesHelper,
  selectDate,
} from 'components/domain/search-travel-dates/dropdowns/useFlightTravelDatesHelper';
import { EDatesInputType } from 'components/domain/search-travel-dates/dropdowns/useTravelDatesDropdownHandler';
import {
  trackCalendarApply,
  trackModalApply,
} from 'components/domain/search-travel-dates/tracking';

const TravelDatesDesktopDropdownWrap = styled.div(
  sx2CssThemeFn({ '.absolute-dropdown': { width: '770px' } })
);

const NUMBER_OF_MONTH_DESKTOP = 2;
const configuration: TravelDatesConfiguration = {
  hasApplyBtn: true,
  hasCustomWeekDayElement: false,
  hasNavbar: true,
  numberOfMonthsToShow: NUMBER_OF_MONTH_DESKTOP,
  canChangeMonth: true,
  closeOnSaveButtonClick: true,
};

type TProps = React.PropsWithChildren<{
  textInputRef: React.RefObject<HTMLInputElement>;
  extendConfig?: Partial<TravelDatesConfiguration>;
  extendCustomConfig?: Partial<TravelDatesCustomConfiguration>;
  range?: boolean;
}>;

const DEFAULT_DURATION_DAYS = 14;

const AllInDesktop: React.FC<TProps> = ({
  textInputRef,
  children,
  extendConfig = {},
  extendCustomConfig = {},
  range,
}: TProps) => {
  const {
    state,
    travelDatesHandlers,
    initialState,
    setTravelDatesData,
    side,
    customConfiguration,
    onChange,
    modalOrDropdownHandler: { isControlOpened, closeControl },
    datesHandler: { closeCalendar, openCalendar, isOpened, getRef },
  } = useCompactSelectContext();

  const [triggerSaveTravelDates, setTriggerSaveTravelDates] = useState(0);
  const moreButtonContainerRef = useRef<HTMLDivElement>(null);
  const travelDatesDropdownRef = useTravelDatesDropdownPosition(
    isControlOpened
  );
  const handleSaveTravelDates = () => {
    if (initialState && (isControlOpened || isOpened(EDatesInputType.ANY))) {
      trackModalApply(initialState, TravelApplySourceType.BACKGROUND);
    }

    closeControl();

    if (customConfiguration?.flightActiveType) return;
    setTriggerSaveTravelDates(value => value + 1);
  };

  const closeOnSelection =
    customConfiguration?.closeOnSelection ||
    extendCustomConfig?.closeOnSelection;

  useCloseDropdownLogic(
    [
      moreButtonContainerRef,
      range ? getRef(EDatesInputType.DEPARTURE_DATE) : textInputRef,
    ],
    handleSaveTravelDates
  );

  const handler = {
    ...travelDatesHandlers,
    setDepartureDate(day: Date) {
      travelDatesHandlers.setDepartureDate(day);

      if (closeOnSelection) {
        if (range) return;
        travelDatesHandlers.setReturnDate(addDays(day, DEFAULT_DURATION_DAYS));
        openCalendar(EDatesInputType.RETURN_DATE)();
      }
    },
    setReturnDate(day: Date) {
      travelDatesHandlers.setReturnDate(day);
      if (closeOnSelection) {
        setTimeout(() => {
          trackCalendarApply({ ...state, returnDate: day });
          closeCalendar();
        });
      }
    },
  };

  const onSaveTravelDates = (input: ITravelDatesState) => {
    if (closeOnSelection) {
      if (
        initialState &&
        input?.returnDate &&
        input?.departureDate &&
        !areTravelDatesEqual(input, initialState)
      ) {
        range ? closeCalendar() : closeControl();
      }

      selectDate(input);
      setTravelDatesData(input);
    } else {
      setTravelDatesData(input);
      setTriggerSaveTravelDates(0);

      if (!triggerSaveTravelDates) {
        trackModalApply(input, TravelApplySourceType.BUTTON);
      }
    }
  };

  const initialMonth = useMemo(() => {
    if (closeOnSelection) {
      return isOpened(EDatesInputType.DEPARTURE_DATE)
        ? state.departureDate
        : state.returnDate;
    }

    return initialState.departureDate;
  }, [initialState, customConfiguration, extendCustomConfig]);

  useFlightTravelDatesHelper({
    travelDatesHandlers,
    customConfiguration,
    state,
  });

  const onChangeExt = e => {
    onChange && onChange(e);
  };

  return (
    <TravelDatesDesktopDropdownWrap>
      <DropdownCalendar
        key={
          isOpened(EDatesInputType.DEPARTURE_DATE)
            ? EDatesInputType.DEPARTURE_DATE
            : EDatesInputType.RETURN_DATE
        }
        dropdownRef={travelDatesDropdownRef}
        containerRef={moreButtonContainerRef}
        side={side}
        travelDatesState={state}
        initialReturnDate={initialState.returnDate}
        travelDatesHandlers={handler}
        customConfiguration={{ ...customConfiguration, ...extendCustomConfig }}
        configuration={{
          ...configuration,
          initialMonth,
          ...extendConfig,
        }}
        isShown={isControlOpened}
        onSaveTravelDates={onSaveTravelDates}
        onChange={onChangeExt}
        triggerSaveTravelDates={triggerSaveTravelDates}
        closeDropdown={closeControl}
      >
        {children}
      </DropdownCalendar>
    </TravelDatesDesktopDropdownWrap>
  );
};

export default AllInDesktop;
