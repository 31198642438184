import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { BsButtonWithIcon } from '@hotelplan/core.fusion.bs-button-with-icon';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

interface ICurrentSearchProps {
  handleEdit(): void;
  items: { content: React.ReactNode; testId?: string }[];
}

const CurrentSearchWrapper = styled.div.attrs({
  'data-id': 'currentSearch',
})(({ theme: { space, FONT_SIZE, colors } }) =>
  sx2CssThemeFn({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: colors.bgLightGrey,
    padding: `${space[3]} ${space[4]}`,
    '> .-items': {
      ...FONT_SIZE.SMALL,
      paddingRight: space[3],
    },
  })
);

const CurrentSearchButton = styled(BsButtonWithIcon)(
  sx2CssThemeFn({
    backgroundColor: 'primary',
    flexShrink: 0,
    color: 'white',
    height: '48px',
    width: '48px',
    '.icon': {
      height: '24px',
      width: '24px',
    },
  })
);

const CurrentSearch: React.FC<ICurrentSearchProps> = ({
  items,
  handleEdit,
}) => {
  const [t] = useTranslation('common');

  const itemsToShow = items.filter(({ content }) => {
    return content !== null;
  });

  return (
    <CurrentSearchWrapper className="currentSearchWrapper">
      <div className="-items">
        {itemsToShow.map(({ content, testId }, i) => {
          return (
            <p data-id={testId} key={i}>
              {content}
            </p>
          );
        })}
      </div>
      <CurrentSearchButton
        icon={{
          name: 'edit',
        }}
        data-id="editButton"
        variant={E_BUTTON_TYPE.CIRCLE_BUTTON_PRIMARY}
        onClick={handleEdit}
      >
        {t('edit')}
      </CurrentSearchButton>
    </CurrentSearchWrapper>
  );
};

export default CurrentSearch;
