import React from 'react';
import ContentLoader from 'react-content-loader';
import styled from 'styled-components';

const CurrentSearchSkeletonWrapper = styled.div.attrs({
  'data-id': 'currentSearch',
})(({ theme: { space, colors } }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: colors.bgLightGrey,
  padding: `${space[3]} ${space[4]}`,
}));

const CurrentSearchSkeleton: React.FC = () => {
  return (
    <CurrentSearchSkeletonWrapper>
      <ContentLoader
        uniquekey="currentSearchSkeleton"
        height={41}
        style={{ width: '100%', height: '41px' }}
      >
        <rect x="0" y="3" rx="7" ry="7" width="250" height="13" />
        <rect x="0" y="25" rx="7" ry="7" width="100" height="13" />
      </ContentLoader>
    </CurrentSearchSkeletonWrapper>
  );
};

export default CurrentSearchSkeleton;
