import { useTranslation } from 'next-i18next';
import React, { useRef } from 'react';
import styled from 'styled-components';
import { MobileModal } from '@hotelplan/components.common.travel-dates';
import CompactDates from 'components/domain/search-travel-dates/dropdowns/compact-select/compact-dates';
import CompactFooter from 'components/domain/search-travel-dates/dropdowns/compact-select/compact-footer';
import { Wrapper } from 'components/domain/search-travel-dates/dropdowns/compact-select/compact-select.styles';
import { useCompactSelectContext } from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';
import CalendarsVariants from './calendars-variants';

const Modal = styled(MobileModal)({
  '.modal-wrapper': {
    maxHeight: '100%',
    borderRadius: 0,
  },
  '.shadow-overlay': {
    backgroundColor: 'white',
  },

  '.compact-dropdown-footer': {
    position: 'sticky',
    top: '100%',
  },
  '.modal-header': {
    padding: '13px 16px',
  },
});

type TProps = React.PropsWithChildren<{
  className?: string;
  label?: string;
  single?: boolean;
}>;

const CompactMobile: React.FC<TProps> = ({
  className,
  label,
  single,
}: TProps) => {
  const [t] = useTranslation(['common', 'forms']);
  const {
    modalOrDropdownHandler: { isControlOpened, closeControl },
    datesHandler: { closeCalendar },
    resetState,
  } = useCompactSelectContext();
  const wrapperRef = useRef<HTMLDivElement>();

  return (
    <Modal
      label={label || ''}
      show={isControlOpened}
      className={className}
      closeModal={() => {
        resetState();
        closeControl();
        closeCalendar();
      }}
    >
      <Wrapper className="mobile" ref={wrapperRef}>
        <CompactDates range={!single}>
          <CalendarsVariants
            range={!single}
            wrapperRef={wrapperRef}
            desktop={false}
          />
        </CompactDates>
      </Wrapper>
      <CompactFooter applyText={t('forms:applyButton.datesMobile')} />
    </Modal>
  );
};

export default CompactMobile;
