import React, { ReactElement } from 'react';
import styled from 'styled-components';
import {
  TravelDatesConfiguration,
  TravelDatesCustomConfiguration,
  TravelDatesMobileModal,
  WeekDaysList,
} from '@hotelplan/components.common.travel-dates';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useCompactSelectContext } from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';
import {
  useFlightTravelDatesHelper,
  selectDate,
} from 'components/domain/search-travel-dates/dropdowns/useFlightTravelDatesHelper';
import { EDatesInputType } from 'components/domain/search-travel-dates/dropdowns/useTravelDatesDropdownHandler';
import {
  trackCalendarApply,
  trackModalApply,
} from 'components/domain/search-travel-dates/tracking';

const TravelDatesMobileDropdownWrap = styled(TravelDatesMobileModal)(
  sx2CssThemeFn({ '.flexible': { mt: ['180px', 0] } })
);

const CompactTravelDatesMobileDropdownWrap = styled(TravelDatesMobileModal)(
  sx2CssThemeFn(({ theme: { FONT_SIZE } }) => ({
    '.modal-header': FONT_SIZE.S,
    '.week-day-list-wrapper': {
      top: 'auto',
    },
  }))
);

const NUMBER_OF_MONTH_MOBILE = 13;
const configuration: TravelDatesConfiguration = {
  hasApplyBtn: true,
  hasCustomWeekDayElement: true,
  hasNavbar: false,
  numberOfMonthsToShow: NUMBER_OF_MONTH_MOBILE,
  canChangeMonth: false,
  closeOnSaveButtonClick: true,
};

type TProps = {
  footerChildren?: ReactElement;
  closeModal?(): void;
  title?: string;
  className?: string;
  extendedCustomConfiguration?: TravelDatesCustomConfiguration;
  extendConfig?: Partial<TravelDatesConfiguration>;
  range?: boolean;
};

const AllInMobile: React.FC<TProps> = ({
  footerChildren,
  closeModal,
  title,
  extendedCustomConfiguration,
  extendConfig = {},
  className,
  range,
}: TProps) => {
  const {
    state,
    localState,
    travelDatesHandlers,
    onChange,
    customConfiguration,
    modalOrDropdownHandler: { isControlOpened, closeControl },
    setTravelDatesData,
    datesHandler: { isOpened, closeCalendar },
    resetLocalState,
  } = useCompactSelectContext();
  const Wrapper = range
    ? CompactTravelDatesMobileDropdownWrap
    : TravelDatesMobileDropdownWrap;

  const isReturnOpened = isOpened(EDatesInputType.RETURN_DATE);

  const keyDate = isReturnOpened
    ? localState.returnDate
    : localState.departureDate;

  const closeAction = closeModal || closeControl;
  const closeModalAction = () => {
    closeAction();
    resetLocalState();
  };

  const handler = {
    ...travelDatesHandlers,
    setReturnDate(day: Date) {
      travelDatesHandlers.setReturnDate(day);
      if (range) {
        setTimeout(() => {
          trackCalendarApply({ ...state, returnDate: day });
          closeCalendar();
        });
      }
    },
  };

  const updateTravelDates = dates => {
    selectDate(dates);
    trackModalApply(dates);
    setTravelDatesData(dates);
  };

  useFlightTravelDatesHelper({
    travelDatesHandlers,
    customConfiguration,
    state,
  });

  return (
    <Wrapper
      key={keyDate.toDateString()}
      className={className}
      travelDatesState={state}
      onChange={onChange}
      travelDatesHandlers={handler}
      show={isControlOpened}
      closeModal={closeModalAction}
      closeOnSaveAction={closeAction}
      customConfiguration={{
        ...customConfiguration,
        hideFooterInformation: true,
        ...extendedCustomConfiguration,
      }}
      configuration={{ ...configuration, ...extendConfig }}
      setTravelDatesData={updateTravelDates}
      footerChildren={footerChildren}
      title={title}
    >
      <WeekDaysList firstDayOfWeek={1} />
    </Wrapper>
  );
};

export default AllInMobile;
