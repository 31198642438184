import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import AllInDesktop from 'components/domain/search-travel-dates/dropdowns/all-in/all-in-desktop';
import AllInMobile from 'components/domain/search-travel-dates/dropdowns/all-in/all-in-mobile';
import { useCompactSelectContext } from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';
import { EDatesInputType } from 'components/domain/search-travel-dates/dropdowns/useTravelDatesDropdownHandler';

const Title = styled.div(({ theme: { colors, FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.XS,
    paddingBottom: '16px',
    margin: '16px 18px -10px',
    borderBottom: `1px solid ${colors.lightGreyFirst}`,
  })
);

type TProps = {
  desktop: boolean;
};

const Range: React.FC<TProps> = ({ desktop }: TProps) => {
  const [t] = useTranslation('common');
  const {
    datesHandler: { getRef, closeCalendar },
  } = useCompactSelectContext();

  const title = t(`range.calendar.title`);

  return (
    <>
      {/* desktop range*/}
      {desktop && (
        <AllInDesktop
          textInputRef={getRef(EDatesInputType.DEPARTURE_DATE)}
          extendConfig={{
            hasApplyBtn: true,
            closeOnSaveButtonClick: false,
            disableFooter: true,
          }}
          extendCustomConfig={{
            closeOnSelection: true,
          }}
          range
        >
          <Title>{title}</Title>
        </AllInDesktop>
      )}
      {/* mobile range*/}
      {!desktop && (
        <AllInMobile
          closeModal={closeCalendar}
          range
          title={title}
          extendedCustomConfiguration={{
            closeOnSelection: true,
          }}
          extendConfig={{
            hasApplyBtn: true,
            closeOnSaveButtonClick: false,
            disableFooter: true,
          }}
        />
      )}
    </>
  );
};

export default Range;
