import React from 'react';
import { useArrayNestedField } from '@hotelplan/components.common.forms';
import {
  IDurationValue,
  ITravelDatesState,
  TravelDatesCustomConfiguration,
  TravelDatesHandlers,
  useTravelDatesState,
} from '@hotelplan/components.common.travel-dates';
import { useIsomorphicLayoutEffect } from '@hotelplan/libs.hooks-react';
import { FlightType } from '@hotelplan/platform-graphql-api';
import {
  TModalOrDropdownHandler,
  useModalOrDropdown,
} from 'components/domain/search-travel-dates/dropdowns/useModalOrDropdown';
import {
  TDatesDropdownHandler,
  useTravelDatesDropdownHandler,
} from 'components/domain/search-travel-dates/dropdowns/useTravelDatesDropdownHandler';
import { SELECT_DAY_EVENT } from 'components/domain/search-travel-dates/search-travel-dates.constants';

type TProps = React.PropsWithChildren<{
  customConfiguration?: TravelDatesCustomConfiguration;
  parentItemIndex?: number;
  parentItemName?: string;
  onChange?: (travelDates: ITravelDatesState | null) => void;
  side?: string;
  customFlexibleDurationValues?: IDurationValue[];
}>;

type TCompactSelectContext = {
  initialState: ITravelDatesState;

  state: ITravelDatesState;
  resetLocalState(b?: boolean): void;
  resetState(): void;
  updateSate(s: ITravelDatesState): void;
  localState: ITravelDatesState;
  localHandlers: TravelDatesHandlers;
  setDuration: TravelDatesHandlers['setDuration'];
  setSearchType: TravelDatesHandlers['setSearchType'];
  customConfiguration?: TravelDatesCustomConfiguration;
  travelDatesHandlers: TravelDatesHandlers;
  datesHandler: TDatesDropdownHandler;
  setTravelDatesData: (nextValue: ITravelDatesState) => void;
  onChange?: (travelDates: ITravelDatesState | null) => void;
  side?: string;
  modalOrDropdownHandler: TModalOrDropdownHandler;
  customFlexibleDurationValues?: IDurationValue[];
};

export const TravelDatesContext = React.createContext<TCompactSelectContext>({
  initialState: undefined,

  state: undefined,
  localState: undefined,
  resetLocalState: undefined,
  resetState: undefined,
  updateSate: undefined,
  localHandlers: undefined,
  setDuration: undefined,
  setSearchType: undefined,
  customConfiguration: undefined,
  travelDatesHandlers: undefined,
  datesHandler: undefined,
  setTravelDatesData: undefined,
  onChange: undefined,
  side: undefined,
  modalOrDropdownHandler: undefined,
  customFlexibleDurationValues: undefined,
});

const TravelDatesContextProvider: React.FC<TProps> = ({
  children,
  parentItemName,
  parentItemIndex,
  customConfiguration,
  onChange,
  side,
  customFlexibleDurationValues,
}: TProps) => {
  const [
    initialState,
    setTravelDatesData,
  ] = useArrayNestedField<ITravelDatesState | null>(
    'travelDates',
    parentItemName,
    parentItemIndex
  );
  const setTravelDatesDataCb = arg => {
    setTravelDatesData(arg);
  };

  const {
    state,
    setDuration,
    setSearchType,
    ...travelDatesHandlers
  } = useTravelDatesState(initialState);

  const { state: localState, ...localHandlers } = useTravelDatesState(
    initialState
  );

  const datesHandler = useTravelDatesDropdownHandler();
  const modalOrDropdownHandler = useModalOrDropdown();

  const resetLocalState = iseRet => {
    if (typeof iseRet === 'undefined') {
      localHandlers.resetDates(state);
    }

    iseRet && localHandlers.resetDates({ returnDate: state.returnDate });
    !iseRet && localHandlers.resetDates({ departureDate: state.departureDate });
  };

  const resetState = () => {
    travelDatesHandlers.resetDates(initialState);
    localHandlers.resetDates(initialState);
  };

  const updateSate = s => {
    travelDatesHandlers.resetDates(s);
    localHandlers.resetDates(s);
  };

  useIsomorphicLayoutEffect(() => {
    if (
      customConfiguration?.flightActiveType === FlightType.Return ||
      !customConfiguration?.flightActiveType
    )
      return;

    const listenSelectDate = event => {
      travelDatesHandlers.setDepartureDate(event.detail.departureDate);
      travelDatesHandlers.setReturnDate(event.detail.returnDate);
    };

    window.addEventListener(SELECT_DAY_EVENT, listenSelectDate);

    return () => {
      window.removeEventListener(SELECT_DAY_EVENT, listenSelectDate);
    };
  }, []);

  return (
    <TravelDatesContext.Provider
      value={{
        state,
        setDuration,
        setSearchType,
        travelDatesHandlers,
        initialState,
        localState,
        localHandlers,
        datesHandler,
        setTravelDatesData: setTravelDatesDataCb,
        customConfiguration,
        onChange,
        side,
        modalOrDropdownHandler,
        resetLocalState,
        resetState,
        updateSate,
        customFlexibleDurationValues,
      }}
    >
      {children}
    </TravelDatesContext.Provider>
  );
};

export function useCompactSelectContext() {
  return React.useContext(TravelDatesContext);
}

export default TravelDatesContextProvider;
