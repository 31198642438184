import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import {
  formatDuration,
  ApplyButton,
  TravelDatesInformation,
} from '@hotelplan/components.common.travel-dates';
import { TravelApplySourceType } from '@hotelplan/libs.tracking';
import { useCompactSelectContext } from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';
import { trackModalApply } from 'components/domain/search-travel-dates/tracking';

const Footer = styled.div(({ theme: { colors } }) => ({
  margin: '0 16px 16px',
  paddingTop: '4px',
  borderTop: `1px solid ${colors.lightGreyFirst}`,
  '.travel-dates-information': {
    textAlign: 'left',
    color: 'black',
    paddingLeft: 0,
  },
  '.applyButton': {
    borderRadius: '4px',
  },
}));

type TProps = {
  applyText: string;
};

const CompactFooter: React.FC<TProps> = ({ applyText }: TProps) => {
  const {
    state,
    initialState,
    customConfiguration,
    setTravelDatesData,
    modalOrDropdownHandler: { closeControl },
    datesHandler: { closeCalendar },
  } = useCompactSelectContext();
  const [t, { language }] = useTranslation(['common', 'forms']);
  const returnDate = state.returnDate || initialState.returnDate;

  return (
    <Footer className="compact-dropdown-footer">
      <TravelDatesInformation
        returnDate={returnDate}
        duration={formatDuration(t, state.duration)}
        departureDate={state.departureDate}
        onlyDates={customConfiguration?.onlyDatesInfo}
        onlyDepartureDate={customConfiguration?.onlyDepartureDate}
        onlyReturnDate={customConfiguration?.onlyReturnDate}
        language={language}
      />
      <ApplyButton
        state={state}
        initialReturnDate={initialState.returnDate}
        saveTravelDates={dates => {
          trackModalApply(dates, TravelApplySourceType.BUTTON);
          setTravelDatesData(dates);
        }}
        postSaveAction={() => {
          closeControl();
          closeCalendar();
        }}
      >
        {applyText}
      </ApplyButton>
    </Footer>
  );
};

export default CompactFooter;
