import { useTranslation } from 'next-i18next';
import React from 'react';
import { TravelDatesControllers } from '@hotelplan/components.common.travel-dates';
import { useCompactSelectContext } from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';

type TProps = React.PropsWithChildren<{
  range?: boolean;
}>;

const DatesController: React.FC<TProps> = ({ children, range }: TProps) => {
  const { t } = useTranslation();
  const {
    state,
    setDuration,
    setSearchType,
    initialState,
    customConfiguration,
    customFlexibleDurationValues,
  } = useCompactSelectContext();

  return (
    <TravelDatesControllers
      travelDatesState={state}
      setDuration={setDuration}
      setSearchType={setSearchType}
      hasCustomWeekDayElement={false}
      labelWithColon={false}
      customConfiguration={customConfiguration}
      initialReturnDate={initialState.returnDate}
      customFlexibleDurationValues={customFlexibleDurationValues}
      labelExact={range && t('range.calendar.search_period.title')}
    >
      {children}
    </TravelDatesControllers>
  );
};

export default DatesController;
