import React from 'react';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import AllInDesktop from 'components/domain/search-travel-dates/dropdowns/all-in/all-in-desktop';
import AllInMobile from 'components/domain/search-travel-dates/dropdowns/all-in/all-in-mobile';
import AllInMobileFooter from 'components/domain/search-travel-dates/dropdowns/all-in/all-in-mobile-footer';
import CompactDesktop from 'components/domain/search-travel-dates/dropdowns/compact-select/compact-desktop';
import CompactMobile from 'components/domain/search-travel-dates/dropdowns/compact-select/compact-mobile';
import DatesController from 'components/domain/search-travel-dates/dropdowns/dates-controller';
import { FeatureList } from 'config/pageConfig.constants';

type TProps = {
  textInputRef: React.RefObject<HTMLInputElement>;
  noVariants?: boolean;
};

enum EDropdownVariant {
  ALL_IN = 'version-a',
  SINGLE_SELECT = 'version-b',
  RANGE_SELECT = 'version-c',
}

const DropdownVariants: React.FC<TProps> = ({
  textInputRef,
  noVariants,
}: TProps) => {
  const { getVariant, isEnabled } = useFeatureToggle();
  const { mobile } = useDeviceType();
  const { channelType } = useAuthentication();

  const variant = getVariant(FeatureList.HP_SEARCH_CONTROL_CALENDAR_VARIANTS)
    ?.name;

  const oldStyle =
    noVariants ||
    !isEnabled(FeatureList.HP_SEARCH_CONTROL_CALENDAR_VARIANTS) ||
    channelType === AuthChannelType.B2B ||
    variant === EDropdownVariant.ALL_IN;

  if (oldStyle)
    return (
      <>
        {mobile && <AllInMobile footerChildren={<AllInMobileFooter />} />}
        {!mobile && (
          <AllInDesktop textInputRef={textInputRef}>
            <DatesController />
          </AllInDesktop>
        )}
      </>
    );

  if (mobile)
    return (
      <CompactMobile
        className="compact-mobile"
        single={variant === EDropdownVariant.SINGLE_SELECT}
      />
    );

  return <CompactDesktop range={variant === EDropdownVariant.RANGE_SELECT} />;
};

export default DropdownVariants;
