import React from 'react';
import { getMinDurationInDays } from '@hotelplan/components.common.travel-dates';
import { addDays } from '@hotelplan/libs.utils';
import Range from 'components/domain/search-travel-dates/dropdowns/compact-select/compact/range';
import Single from 'components/domain/search-travel-dates/dropdowns/compact-select/compact/single';
import { useCompactSelectContext } from 'components/domain/search-travel-dates/dropdowns/travel-dates-context';
import { EDatesInputType } from 'components/domain/search-travel-dates/dropdowns/useTravelDatesDropdownHandler';
import { SearchPeriodType } from 'graphql/types/resolversTypes';

type TProps = {
  desktop: boolean;
  range: boolean;
  wrapperRef: React.MutableRefObject<HTMLDivElement>;
};

const CalendarsVariants: React.FC<TProps> = ({
  desktop,
  wrapperRef,
  range,
}: TProps) => {
  const {
    state,
    datesHandler: { isOpened },
  } = useCompactSelectContext();

  const isReturn = isOpened(EDatesInputType.RETURN_DATE);

  const minReturnDate =
    state.searchType === SearchPeriodType.Exact
      ? addDays(state.departureDate, 1)
      : addDays(state.departureDate, getMinDurationInDays(state.duration));

  return (
    <>
      {/* single*/}
      {!range && isOpened(EDatesInputType.ANY) && (
        <Single
          desktop={desktop}
          isReturn={isReturn}
          wrapperRef={wrapperRef}
          minReturnDate={minReturnDate}
        />
      )}

      {/* range*/}
      {range && isOpened(EDatesInputType.ANY) && <Range desktop={desktop} />}
    </>
  );
};

export default CalendarsVariants;
